/* eslint-disable camelcase */

export default {
  store: {
    Own_design: `Свой дизайн`,
    Create_own_design: `Создать свой дизайн`,
    Show_more: `Показать ещё`,
    Select_the_desired_section_or_product: `Выберите нужный раздел или товар`,
    Sort: `Сортировка`,
    Didnt_find_what_you_were_looking_for: `Не нашли, что искали`
  },
  editor: {
    "Constructor": `Конструктор`,

    "name": `Полное имя`,
    "job_title": `Должность`,
    "email": `Е-мэйл`,
    "phone": `Телефон`,
    "address1": `Адрес 1`,
    "address2": `Адрес 2`,
    "website": `Веб-адрес`,
    "mobile_phone": `Мобильный телефон`,
    "company_name": `Название компании`,

    "glossy": `глянцевая`,
    "matte": `матовая`,
    "none": `отсутствует`,

    "curious_white": `маджестик белый мрамор`,
    "curious_gold": `маджестик сияние золота`,
    "curious_creme": `кириус кремовый`,
    "plike_yellow": `плайк желтый`,
    "plike_orange": `плайк оранжевый`,
    "plike_purple": `плайк фиолетовый`,
    "linen_white": `лён белый`,
    "linen_grey": `лён серый`,
    "linen_ivory": `лён слоновая кость`,
    "touchcover_white": `тачкавер белый`,
    "touchcover_ivory": `тачкавер слоновая кость`,

    "no_frame": `Без рамки`,
    "dark_wood": `Темное дерево`,
    "light_wood": `Светлое дерево`,

    "soft_touch_white": `Soft touch белый`,
    "soft_touch_black": `Soft touch чёрный`,
    "soft_touch_translucent": `Soft touch матовый полупрозрачный`,
    "leather_white": `Белая кожа`,
    "leather_black": `Чёрная кожа`,
    "silicone_clear": `Прозрачный силиконовый`,
    "leather": `Кожа`,
    "polyester": `Полиэстер`,

    "vinyl": `Виниловая плёнка`,
    "resin": `Заливка смолой - основа ПВХ`,
    "magnet": `Магнитная основа`,

    "red-handle": `красная ручка и ободок`,
    "light-green-handle": `светло-зелёная ручка и ободок`,
    "burgundy-handle": `бордовая ручка и ободок`,
    "orange-handle": `оранжевая ручка и ободок`,
    "blue-handle": `голубая ручка и ободок`,
    "light-green-inside": `светло-зелёная внутри`,
    "blue-inside": `голубая внутри`,
    "pink-inside": `розовая внутри`,
    "red-inside": `красная внутри`,
    "orange-inside": `оранжевая внутри`,
    "black-inside": `чёрная внутри`,
    "yellow-inside": `жёлтая внутри`,

    "white": `белый`,
    "grey": `серый`,
    "red": `красный`,
    "orange": `оранжевый`,
    "yellow": `жёлтый`,
    "green": `зелёный`,
    "blue": `голубой`,
    "purple": `фиолетовый`,
    "violet": `фиолетовый`,
    "black": `чёрный`,
    "beige": `бежевый`,
    "pistachio": `фисташковый`,
    "burgundy": `бордовый`,
    "fuchsia": `фуксия`,
    "pink": `розовый`,
    "silver": `серебристый`,
    "gold": `золотистый`,
    "lilac": `сиреневый`,
    "ivory": `слоновая кость`,
    "sand": `песочный`,
    "torquoise": `бирюзовый`,
    "melange": `меланж`,
    "brown": `коричневый`,
    "coral": `коралловый`,
    "design": `рисунок`,
    "cornflower": `василёк`,
    "translucent": `матовый полупрозрачный`,
    "turquoise": `мятный`,
    "unpainted": `неокрашенный`,

    "light-grey": `светло-серый`,
    "dark-grey": `тёмно-серый`,
    "light-green": `светло-зелёный`,
    "dark-green": `тёмно-зелёный`,
    "light-blue": `светло-голубой`,
    "dark-blue": `тёмно-синий`,
    "bright-blue": `ярко-синий`,
    "bright-green": `ярко-зелёный`,
    "dark-red": `тёмно-красный`,
    "lime-green": `салатовый`,
    "sky-blue": `небесный`,
    "light-brown": `светло-коричневый`,
    "grey-blue": `серо-голубой`,
    "natural-linen": `натуральный`,
    "yellow-neon": `жёлтый неон`
  },
  item: {
    "glossy": `Глянцевое`,
    "matte": `Матовое`,
    "none": `Нет`,

    "curious_white": `маджестик белый мрамор`,
    "curious_gold": `маджестик сияние золота`,
    "curious_creme": `кириус кремовый`,
    "plike_yellow": `плайк желтый`,
    "plike_orange": `плайк оранжевый`,
    "plike_purple": `плайк фиолетовый`,
    "linen_white": `лён белый`,
    "linen_grey": `лён серый`,
    "linen_ivory": `лён слоновая кость`,
    "touchcover_white": `тачкавер белый`,
    "touchcover_ivory": `тачкавер слоновая кость`,

    "frameType": {
      no_frame: `Без рамки`,
      dark_wood: `Темное дерево`,
      light_wood: `Светлое дерево`,
      black: `Темная`,
      white: `Белая`,
      silver: `Серебристая`
    },

    "soft_touch_white": `Soft touch белый`,
    "soft_touch_black": `Soft touch чёрный`,
    "soft_touch_translucent": `Soft touch матовый полупрозрачный`,
    "leather_white": `Белая кожа`,
    "leather_black": `Чёрная кожа`,
    "silicone_clear": `Прозрачный силиконовый`,
    "leather": `Кожа`,
    "polyester": `Полиэстер`,

    "vinyl": `Виниловая плёнка`,
    "resin": `Заливка смолой - основа ПВХ`,
    "magnet": `Магнитная основа`,

    "red-handle": `красная ручка и ободок`,
    "light-green-handle": `светло-зелёная ручка и ободок`,
    "burgundy-handle": `бордовая ручка и ободок`,
    "orange-handle": `оранжевая ручка и ободок`,
    "blue-handle": `голубая ручка и ободок`,
    "light-green-inside": `светло-зелёная внутри`,
    "blue-inside": `голубая внутри`,
    "pink-inside": `розовая внутри`,
    "red-inside": `красная внутри`,
    "orange-inside": `оранжевая внутри`,
    "black-inside": `чёрная внутри`,
    "yellow-inside": `жёлтая внутри`,

    "white": `белый`,
    "grey": `серый`,
    "red": `красный`,
    "orange": `оранжевый`,
    "yellow": `жёлтый`,
    "green": `зелёный`,
    "blue": `голубой`,
    "purple": `фиолетовый`,
    "violet": `фиолетовый`,
    "black": `чёрный`,
    "beige": `бежевый`,
    "pistachio": `фисташковый`,
    "burgundy": `бордовый`,
    "fuchsia": `фуксия`,
    "pink": `розовый`,
    "silver": `серебристый`,
    "gold": `золотистый`,
    "lilac": `сиреневый`,
    "ivory": `слоновая кость`,
    "sand": `песочный`,
    "torquoise": `бирюзовый`,
    "melange": `меланж`,
    "brown": `коричневый`,
    "coral": `коралловый`,
    "design": `рисунок`,
    "cornflower": `василёк`,
    "translucent": `матовый полупрозрачный`,
    "turquoise": `мятный`,
    "unpainted": `неокрашенный`,

    "light-grey": `светло-серый`,
    "dark-grey": `тёмно-серый`,
    "light-green": `светло-зелёный`,
    "dark-green": `тёмно-зелёный`,
    "light-blue": `светло-голубой`,
    "dark-blue": `тёмно-синий`,
    "bright-blue": `ярко-синий`,
    "bright-green": `ярко-зелёный`,
    "dark-red": `тёмно-красный`,
    "lime-green": `салатовый`,
    "sky-blue": `небесный`,
    "light-brown": `светло-коричневый`,
    "grey-blue": `серо-голубой`,
    "natural-linen": `натуральный`,
    "yellow-neon": `жёлтый неон`,

    "standart": `эконом`,
    "premium": `премиум`,

    "cotton": `хлопок`,
    "synthetics": `синтетика`,
    "bouffant": `с начёсом`,
    "eyelet": `петелька`,

    "milk": `молочный`,
    "bitter": `горький`,

    "cornerRadius": {
      "0": `Нет`,
      "4": `4 мм`
    },
    "diameter": {
      "32": `32 мм`,
      "44": `44 мм`,
      "56": `56 мм`
    },
    "notebookCover": {
      glossy: `глянцевая`,
      matte: `матовая`,
      plastic: `пластиковая`,
      none: `отсутствует`
    },
    "numberOfPages": {
      "48_pages": `48 листов`,
      "96_pages": `96 листов`
    },
    "pageGrid": {
      squared: `клетка`,
      ruled: `линейка`,
      none: `отсутствует`
    },
    "numberOfStickers": {
      "500": `500 шт.`,
      "750": `750 шт.`,
      "1000": `1000 шт.`,
    },
    "pillowCase": {
      sew: `зашита`,
      zipper: `на молнии`,
      zipper_without_extender: `на молнии без наполнителя`
    },
    "pole": {
      tubule: `трубочка 33 см`,
      pocket: `карман (под палку)`,
      eyelets: `люверсы`,
      shaft150: `древко 150 см`,
      shaft200: `древко 200 см`
    },
    "dimensionSize": {
      "60x60": `60x60 см`,
      "70x70": `70x70 см`
    },
    "rubber": `резиновый`,
    "plastic": `пластиковый Slim`
  },
  survey: {
    Yes: `Да`,
    No: `Нет`,
    Send: `Отправить`,
    Write_please: `Напишите, пожалуйста`
  },
  header: {}
};
